
import AuthImage from '@/components/image/AuthImage.vue'
import JigTooltip from '@/components/input/JigTooltip.vue'
import ChipList from '@/components/material/ChipList.vue'
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import { JigConst } from '@/constants'
import FiltersMixin from '@/mixin/Filters'
import { Permissions } from '@/security/permissions'
import { PermissionActions } from '@/store/constants'
import { Tenant } from '@/store/modules/app/types'
import { LibraryCategories, LibraryCategory, LibraryEntry } from '@/store/modules/teamlibrary/types'
import { ImageValidationResult, Namespace, UploadThumbnailRequest } from '@/store/types'
import { ValidationRules } from '@/utils/input-validation'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { SortCategoriesByOrderAsc } from '@/views/LibraryCategories.vue'
import { mixins } from 'vue-class-component'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'

@Component({
  components: {
    'jig-tooltip': JigTooltip,
    'prompt-yes-no': PromptYesNo,
    'chip-list': ChipList,
    'auth-image': AuthImage,
  },
})
export default class LibraryEntryVue extends mixins(FiltersMixin) {
  @State('libraryEntry', { namespace: Namespace.TeamLibrary })
  public libraryEntry!: LibraryEntry
  @State('libraryCategories', { namespace: Namespace.TeamLibrary })
  public libraryCategories!: LibraryCategories
  @State('myTenantIndex', { namespace: Namespace.App }) public myTenantIndex!: number
  @State('myTenants', { namespace: Namespace.App }) public myTenants!: Tenant[]
  @State('myTenant', { namespace: Namespace.App }) public myTenant!: Tenant

  @Getter('isPreparingApp', { namespace: Namespace.Utils })
  public isPreparingApp!: boolean

  @Action('loadLibraryCategories', { namespace: Namespace.TeamLibrary })
  public loadLibraryCategories: any
  @Action('loadLibraryEntry', { namespace: Namespace.TeamLibrary })
  public loadLibraryEntry: any
  @Action('deleteLibraryEntryById', { namespace: Namespace.TeamLibrary })
  public deleteLibraryEntryById: any
  @Action('updateLibraryEntry', { namespace: Namespace.TeamLibrary })
  public updateLibraryEntry: any
  @Action('uploadLibraryEntryThumbnail', { namespace: Namespace.TeamLibrary })
  public uploadLibraryEntryThumbnail: any

  public $refs!: Vue['$refs'] & {
    deleteEntryPromptYesNo: PromptYesNo
    tagsChipList: ChipList
    publishEntryPromptYesNo: PromptYesNo
  }

  private visibilityPublic: string = JigConst.JigVisibilities.Public

  private selectedEntry: any = { Id: '', DisplayName: '', Visibility: '' }
  private validLibraryCategories: LibraryCategory[] = []
  private formIsValid: boolean = false
  private nameRules!: any
  private uploadRules!: any
  private uploadExternalValidationErrors: string[] = []

  private visibilityItems!: any
  private needPublishCheck: boolean = false
  private visibilityAlreadyPublic: boolean = false
  private readonly stateIdHidden = 5
  private readonly stateIdDeprecated = 999

  private thumbnailToUpload!: File
  private thumbnailToUploadArrayBuffer: any = ''
  private isShowUploadThumb: boolean = false
  private isUploadingThumbnail: boolean = false
  private isThumbFileSelected: boolean = false
  private isThumbnailUploadValid: boolean = false

  private isJigSpaceStaff: boolean = false
  private canEdit: boolean = false
  private canDelete: boolean = false
  private myTenantID: number = -1

  private tagRules: any = {
    extra: [(v: any) => (v && !v.includes(',')) || 'Comma is not allowed'],
  }

  @Watch('myTenant')
  private onMyTenantChanged(value: Tenant) {
    this.myTenantID = value.ID
  }

  private deleteEntryById() {
    this.deleteLibraryEntryById(this.selectedEntry.Id)
    this.$router.push({ path: '/library' })
  }

  protected async created() {
    this.visibilityItems = [
      {
        text: 'Public',
        value: JigConst.JigVisibilities.Public,
      },
      {
        text: 'Team',
        value: 'tenant',
      },
      {
        text: 'Private',
        value: JigConst.JigVisibilities.Public,
      },
    ]

    this.nameRules = ValidationRules.RequiredName
    this.uploadRules = ValidationRules.PngImageUpload

    const id = this.$route.params.id.toString()
    let promiseEntry = null
    const promiseCategories = this.loadLibraryCategories({}).then(
      (promiseEntry = this.loadLibraryEntry({
        Id: id,
      }))
    )
    if (await promiseCategories) {
      if (this.libraryCategories != null) {
        for (const c of this.libraryCategories.Categories) {
          if (c.StateId !== this.stateIdHidden) {
            const cat = Object.assign({}, c)
            if (cat.StateId === this.stateIdDeprecated) {
              cat.DisplayName = cat.DisplayName + ' (Deprecated)'
            }
            this.validLibraryCategories.push(cat)
            this.validLibraryCategories.sort(SortCategoriesByOrderAsc)
          }
        }
      }

      await this.awaitLibraryEntryLoad(promiseEntry)
    }
  }

  protected async mounted() {
    this.checkPermissions()
  }

  private get thumbnailSrc(): string {
    return this.libraryEntry.ThumbnailUrl || ''
  }

  private async awaitLibraryEntryLoad(promiseLoadEntry: Promise<boolean>) {
    if (await promiseLoadEntry) {
      this.$refs.tagsChipList.Reset()
      this.visibilityAlreadyPublic = this.libraryEntry.Visibility === JigConst.JigVisibilities.Public
    }
  }

  private showDeleteEntryPrompt(item: any) {
    this.selectedEntry = item
    this.$refs.deleteEntryPromptYesNo.ShowPrompt()
  }

  private async onPublishCheckSuccess() {
    this.needPublishCheck = false
    this.saveChanges()
  }

  private async saveChanges() {
    if (this.needPublishCheck) {
      this.selectedEntry = this.libraryEntry
      this.$refs.publishEntryPromptYesNo.ShowPrompt()
      return
    }

    this.libraryEntry.Tags = this.$refs.tagsChipList.EditedItems
    if (await this.updateLibraryEntry(this.libraryEntry)) {
      const promiseLoadEntry = this.loadLibraryEntry({
        Id: this.libraryEntry.Id,
      })
      this.awaitLibraryEntryLoad(promiseLoadEntry)
    }
  }

  private checkAboutToPublish(option: any) {
    if (!this.visibilityAlreadyPublic) {
      this.needPublishCheck = option === JigConst.JigVisibilities.Public
    } else {
      this.needPublishCheck = option !== JigConst.JigVisibilities.Public
    }
  }

  private async uploadThumbnailClick() {
    const req: UploadThumbnailRequest = {
      Id: this.libraryEntry.Id.toString(),
      TenantId: this.myTenantID,
      Thumbnail: this.thumbnailToUpload,
      IsFirstThumbnail: this.libraryEntry.ThumbnailUrl.includes('placeholder'),
    }
    this.isUploadingThumbnail = true
    if (await this.uploadLibraryEntryThumbnail(req)) {
      const promiseLoadEntry = this.loadLibraryEntry({
        Id: this.libraryEntry.Id,
      })
      this.awaitLibraryEntryLoad(promiseLoadEntry)
    }
    this.isUploadingThumbnail = false
    this.isShowUploadThumb = false
  }

  private async thumbFileInputChange(file: File) {
    // To display a thumbnail preview, we set a v-img src to the FileReader result.
    if (FileReader && file && file.size) {
      const fr = new FileReader()
      fr.onload = () => {
        this.thumbnailToUploadArrayBuffer = fr.result
      }
      fr.readAsDataURL(file)
    } else {
      this.thumbnailToUploadArrayBuffer = ''
    }

    if (file && file.size) {
      this.thumbnailToUpload = file
      this.isThumbFileSelected = true
      this.uploadExternalValidationErrors = []
      this.isThumbnailUploadValid = false
      await ValidationRules.ImageSizeLibraryThumbnail(file).then((result: ImageValidationResult) =>
        this.onThumbnailValidationComplete(result)
      )
    } else {
      this.isThumbFileSelected = false
    }
  }

  private onThumbnailValidationComplete(result: ImageValidationResult) {
    this.isThumbnailUploadValid = result.valid
    if (!result.valid) {
      this.uploadExternalValidationErrors = [
        `Thumbnail dimensions must be no larger than
        ${result.xMax} x ${result.yMax}px. Supplied dimensions: ${result.x} x ${result.y}px. `,
      ]
    }
  }

  @Watch('myTenantIndex')
  private onMyTenantIndexPropertyChanged(value: number, oldValue: number) {
    this.checkPermissions()
  }

  private checkPermissions() {
    this.canEdit = false
    this.canDelete = false
    this.isJigSpaceStaff = false
    const tenant = TenantHelpers.GetTenantByIndex(this.myTenantIndex, this.myTenants)
    if (tenant !== null && tenant !== undefined) {
      this.isJigSpaceStaff = tenant.ID === TenantHelpers.GetJigSpaceStaffTenantID()
      const tenantTokens = this.$auth0.tenants
      const tenantToken = TenantHelpers.GetTenantTokenByTenantID(tenantTokens, tenant.ID)
      if (tenantToken !== undefined) {
        this.canEdit = Permissions.TokenHasPermissionAction(tenantToken, Permissions.PermLibrary, PermissionActions.Edit)
        this.canDelete = Permissions.TokenHasPermissionAction(tenantToken, Permissions.PermLibrary, PermissionActions.Delete)
      }
    }
  }
}
